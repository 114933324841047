import Vue from 'vue';
import Modal from './components/modal';
import MobileNav from './components/mobile-nav';
import Accordion from './components/accordion';
import PromptModal from './mixins/prompt-modal';
import VideoModal from './mixins/video-modal';
import GlobalMixin from './mixins/global-mixin';
import gdpr from './util/gdpr.js';
import analytics from './util/analytics';

Vue.component('modal', Modal);
Vue.component('mobile-nav', MobileNav);
Vue.component('accordion', Accordion);
Vue.mixin(PromptModal);
Vue.mixin(VideoModal);
Vue.prototype.trackEvent = analytics.trackEvent.bind(analytics);

class VueSingleton {
  /**
   * Create a new instance of Vue Singleton and setup the default options,
   * we always want to bind to the outmost container. (#site-container).
   * Overwrite the  default delimiters to avoid twig collision.
   */
  constructor() {
    this.defaultOptions = {
      delimiters: ['${', '}'],
      el: '#site-container'
    };
    this.options = {};
    this.instance = null;
  }

  /**
   * Get the Vue instance options to use when creating vue.
   * @param {Object} options The vue options.
   */
  setOptions(options) {
    this.options = options;
  }

  /**
   * Creates an instance of Vue and makes sure only one can be created.
   * @return {Object} The Vue instance.
   */
  instantiate() {
    if (this.instance) {
      return this.instance;
    }

    // Add our global mixin to the root instance.
    if (!this.options.mixins) {
      this.options.mixins = [GlobalMixin];
    } else {
      this.options.mixins.push(GlobalMixin);
    }

    this.options.mixins.push({
      mounted: function() {
        // When each page loads, check to see if we have google campaign data.
        // If so, set it in all our forms to funnel into leads.
        let campaignData = analytics.getCampaignData();
        if (campaignData) {
          this.$el.querySelectorAll('form').forEach((form) => {
            form.querySelectorAll('input[name=campaignSource]').forEach((input) => {
              input.value = campaignData.campaign_source;
            });
            form.querySelectorAll('input[name=campaignMedium]').forEach((input) => {
              input.value = campaignData.campaign_medium;
            });
            form.querySelectorAll('input[name=campaignName]').forEach((input) => {
              input.value = campaignData.campaign_name;
            });
          });
        }
      }
    });

    this.instance = new Vue({
      ...this.options,
      ...this.defaultOptions
    });

    return this.instance;
  }
}

const instance = new VueSingleton();

export default instance;
